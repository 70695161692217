import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBiohazard, faBuilding, faCogs, faEnvelope, faExternalLink, faEye, faPhoneAlt, faQuestionCircle, faRightFromBracket, faSearch, faUser, faUserCog, faUserSecret, faUsers, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";


import { useGlobal } from "../../context/globals";
import { useUser } from "../../context/userProvider";
import Dropdown from "../Dropdown";
import Avatar from "../Avatar";
import { STAGE, myToast, urls } from "../../common";
import Tooltip from "../Tooltip";
import Button from "../Button";
import useOnClickOutside from "../../utilities/hooks/useOnClickOutside";
import { useCompany } from "../../context/companyProvider";


type PropsSearch = {
    id: string,
    name: string,
    company: string,
    company_id: string,
    town: string,
    postcode: string,
    type: string
};


const Header = () => {
    const { user } = useUser();
    const { global, setGlobal } = useGlobal();
    const { company } = useCompany()

    const [search, setSearch] = useState<string>();
    const [searchResults, setSearchReuslts] = useState<PropsSearch[]>([]);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [openSearch, setOpenSearch] = useState<boolean>(false)
    const menuBar = useRef(null);

    useEffect(() => {
        if (!!search && search.length > 0) {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }

            const newTimeout = setTimeout(() => {
                setOpenSearch(true);
                axios.post(`${urls.remoteURL}get-search`, { search })
                    .then(({ data }) => {
                        setSearchReuslts(data);
                    })
                    .catch(error => {
                        myToast({ title: 'Error', message: error.response.data.message || 'Error searching.', icon: faBiohazard, colour: 'red', timing: 3 });
                    })
            }, 1000)

            setTypingTimeout(newTimeout);
        }
    }, [search]);

    useOnClickOutside(menuBar, () => {
        setOpenSearch(false);
    });

    return (
        <header className="top-bar">
            {!!user &&
                <button
                    className="menu-toggler"
                    onClick={() => setGlobal(prev => ({ ...prev, menuOpen: !global.menuOpen }))}
                ><FontAwesomeIcon icon={faBars} /></button>
            }

            {!company.logo ? <span className="brand relative">Rhino Safety</span> : <img src={urls.remoteURL + 'company/' + company.id + '/logo'} className="max-h-full p-2" />}

            {STAGE !== 'production' &&
                <span className="ml-5 bg-red-600 text-white font-bold p-2 rounded-lg">DEV AREA</span>
            }


            {!!user &&

                <div className="hidden md:block ml-10">
                    <Dropdown
                        arrow={false}
                        trigger={'manual'}
                        show={openSearch}
                        content={
                            <div ref={menuBar} className="relative min-w-[400px]">
                                <div className="absolute top-2 right-2">
                                    <Button color="gray" className="z-10 " onClick={() => setOpenSearch(prev => !prev)}><FontAwesomeIcon icon={faXmark} fixedWidth /></Button>
                                </div>
                                <ul className="divide-y divide-gray-100">
                                    {searchResults.length === 0 ? <li className="z-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900">No Results Found?</li> :
                                        searchResults.map(x => (
                                            <li key={x.id + x.type} className="relative rounded-lg p-4 hover:bg-gray-50">
                                                <Link
                                                    to={`/people/${x.id}${!!company?.edit_people ? '/edit' : ''}`}
                                                    className=" text-gray-900"
                                                    onClick={() => setOpenSearch(false)}
                                                >
                                                    <p className="font-semibold"><FontAwesomeIcon icon={faUser}
                                                        fixedWidth
                                                        className="text-gray-400 mr-2" />
                                                        {x.name}
                                                    </p>
                                                    <span className="absolute inset-0" />
                                                </Link>
                                            </li>
                                        ))}
                                </ul>
                            </div>}
                    >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                defaultValue={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onFocus={() => { if (!!search && search?.length > 3) setOpenSearch(true); }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </Dropdown>
                </div>
            }

            <span className="ml-auto bg-rhinoBlue-600 text-white font-bold p-2 rounded-lg hidden md:block">For support, call <a className="text-white" href="tel:++4401270440341">01270 440 341</a> or <a href="dashboard@rhinosafety.co.uk" className="text-white">email <FontAwesomeIcon icon={faExternalLink} size="xs" /></a> </span>
            <span className="ml-auto bg-rhinoBlue-600 text-white font-bold p-2 rounded-lg block md:hidden">Support <a className="text-white" href="tel:++4401270440341"><FontAwesomeIcon icon={faPhoneAlt} /></a> or <a href="dashboard@rhinosafety.co.uk" className="text-white"><FontAwesomeIcon icon={faEnvelope} /></a> </span>

            {!!user &&
                <div className="flex items-center ">

                    {/* Notifications 
                    <div className="self-stretch">
                        <Dropdown
                            arrow={true}
                            trigger={'click'}
                            content={
                                <div>
                                    <div className="flex items-center px-5 py-2">
                                        <h5 className="mb-0 uppercase">Notifications</h5>
                                        <button className="btn btn-outlined btn_warning uppercase ml-auto">
                                            Clear All
                                        </button>
                                    </div>
                                    <hr />
                                    <div className="p-5 hover:bg-blue-50">
                                        <a href="#no-link">
                                            <h6 className="uppercase">Heading One</h6>
                                        </a>
                                        <p>Lorem ipsum dolor, sit amet consectetur.</p>
                                        <small>Today</small>
                                    </div>
                                    <hr />
                                    <div className="p-5 hover:bg-blue-50">
                                        <a href="#no-link">
                                            <h6 className="uppercase">Heading Two</h6>
                                        </a>
                                        <p>Mollitia sequi dolor architecto aut deserunt.</p>
                                        <small>Yesterday</small>
                                    </div>
                                    <hr />
                                    <div className="p-5 hover:bg-blue-50">
                                        <a href="#no-link">
                                            <h6 className="uppercase">Heading Three</h6>
                                        </a>
                                        <p>Nobis reprehenderit sed quos deserunt</p>
                                        <small>Last Week</small>
                                    </div>
                                </div>
                            }
                        >
                            <button className="relative flex items-center h-full ml-1 px-2 text-2xl leading-none text-gray-700">
                                <FontAwesomeIcon icon={faBell} fixedWidth />
                                <span className="absolute top-0 right-0 rounded-full border border-gray-400 -mt-2 mr-0 px-1 leading-tight text-xs text-gray-700 bg-white">
                                    3
                                </span>
                            </button>
                        </Dropdown>
                    </div>
                    */}

                    {/* User Menu */}
                    <div>
                        <Dropdown
                            arrow={true}
                            trigger={'click'}
                            content={
                                <div className="w-64">
                                    <div className="p-5">
                                        <h5 className="uppercase">{user.fullname}</h5>
                                        <p>{user.email}</p>
                                        <p className="italic text-xs">(Impersonated by {user.impersonator})</p>
                                    </div>
                                    <hr />
                                    <div className="p-5 space-y-2">
                                        <Link
                                            to={'people/' + user.id}
                                            className="flex gap-2 text-gray-700 hover:text-rhinoBlue-700"
                                        >
                                            <FontAwesomeIcon icon={faUserCog} />
                                            View Profile
                                        </Link>
                                        <Link
                                            to={'support'}
                                            className="flex gap-2 text-gray-700 hover:text-rhinoBlue-700"
                                        >
                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                            Support
                                        </Link>
                                        {/* <Link
                                            to={'faqs/'}
                                            className="flex gap-2 text-gray-700 hover:text-rhinoBlue-700"
                                        >
                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                            FAQs
                                        </Link> */}
                                        {/* <Link
                                            to={'settings/'}
                                            className="flex gap-2 text-gray-700 hover:text-rhinoBlue-700"
                                        >
                                            <FontAwesomeIcon icon={faCogs} />
                                            Company Settings
                                        </Link> */}
                                    </div>
                                    <hr />
                                    <div className="p-5">
                                        <Link
                                            to="logout"
                                            className="flex gap-2 items-center text-gray-700 dark:text-gray-500 hover:text-rhinoBlue-700"
                                        >
                                            <FontAwesomeIcon icon={faRightFromBracket} fixedWidth />
                                            Logout
                                        </Link>
                                    </div>
                                </div>
                            }
                        >
                            <button className="ml-4">
                                <Avatar impersonator={!!user.impersonator}>
                                    {user.firstname && user.firstname[0]}{user.lastname && user.lastname[0]}
                                </Avatar>
                            </button>
                        </Dropdown>
                    </div>
                </div>
            }
        </header >
    )
}

export default Header;
