import React, { useRef, useState } from "react";
import { useGlobal } from "../../context/globals";
import Backdrop from "../Backdrop";
import Tippy, { useSingleton } from "@tippyjs/react";
import classNames from "classnames";
import useOnClickOutside from "../../utilities/hooks/useOnClickOutside";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faChildren, faDashboard, faEye, faFiles, faLayerGroup, faListTimeline, faObjectGroup, faPencil, faPeople, faPersonFallingBurst, faPresentation, faQuestionCircle, faUser, faUserTieHair, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { useCompany } from "../../context/companyProvider";
import { PropsCompliance } from "../../Pages/Compliance/types";
import { getComplianceMenuTypes } from "../../Pages/Compliance/common";
// import { getComplianceMenuTypes } from "../../Pages/Compliance/common";
// import { PropsCompliance } from "../../Pages/Compliance/types";

export interface PropsMenu {
    id: string,
    name: string,
    to?: string,
    icon?: IconDefinition,
    hidden?: boolean,
    direct?: boolean,
    closeMenu?: boolean,
    childrenOpen?: boolean,
    children?: boolean | PropsMenu[],
    current?: boolean,
    href?: string,
    target?: string,
}

const SideBar = () => {
    const { global } = useGlobal();
    const { company } = useCompany();

    const location = useLocation();
    const params = location.pathname.split('/');

    const [source, target] = useSingleton();
    const menuBar = useRef(null);

    const [activeMenus, setActiveMenus] = useState<string[]>(params);
    const [activeSubMenu, setActiveSubMenu] = useState<string>(params[1]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isBackdropActive, setIsBackdropActive] = useState<boolean>(false);

    const menuItems: PropsMenu[] = [
        { id: 'dashboard', name: 'Dashboard', to: 'dashboard', icon: faDashboard, children: false },
        {
            id: 'people', name: 'People', to: 'people', icon: faPeople, closeMenu: true, childrenOpen: !!global.person, direct: !global.person,
            children: [
                { id: 'people-view-all', name: 'View All', to: `/people`, icon: faEye, closeMenu: true },
                // { id: 'people-add', name: 'Create Person', to: '/people/add', icon: faPlusCircle, closeMenu: true },
                { id: 'people-name', name: global.person?.fullname as string, icon: faUser, hidden: !global.person, },
                { id: 'people-edit', name: 'Edit', to: `/people/${global.person?.id}/edit`, icon: faPencil, hidden: !global.person, },
                { id: 'people-locations', name: 'Locations', to: `/people/${global.person?.id}/locations`, icon: faLayerGroup, hidden: !global.person, },
            ], hidden: !company.view_people
        },

        {
            id: 'compliance', name: 'Compliance', to: 'compliance', icon: faListTimeline, closeMenu: true, childrenOpen: !!global.compliance, direct: !global.compliance,
            children: [
                { id: 'compliance-view-all', name: 'View All', to: `/compliance`, icon: faEye, closeMenu: true },
                { id: 'compliance-name', name: global.compliance?.name as string, icon: faListTimeline, hidden: !global.compliance, },
                ...getComplianceMenuTypes(global.compliance as PropsCompliance, company)
            ], hidden: !company.view_items
        },

        // {
        //     id: 'locations', name: 'Locations', to: `locations`, icon: faLayerGroup, hidden: !company.view_sites, closeMenu: true, childrenOpen: !!global.location, direct: !global.location,
        //     children: [
        //         { id: 'location-view-all', name: 'View All', to: `/compliance`, icon: faEye, closeMenu: true },
        //         { id: 'location-name', name: global.location?.name as string, icon: faListTimeline, hidden: !global.compliance, },
        //         { id: 'location-edit', name: 'Edit', to: `/locations/${global.location?.id}/edit`, icon: faPencil, hidden: !global.location, },
        //         { id: 'location-sub-locations', name: 'Sub Locations', to: `/locations/${global.location?.id}/children`, icon: faChildren, hidden: !global.location, },
        //         { id: 'location-managers', name: 'Managers', to: `/locations/${global.location?.id}/managers`, icon: faUserTieHair, hidden: !global.location, },
        //         { id: 'location-people', name: 'People', to: `/locations/${global.location?.id}/people`, icon: faUsers, hidden: !global.location, },

        //     ]
        // },



        { id: 'accidents', name: 'Accidents/Incidents', to: 'accidents', icon: faPersonFallingBurst, children: false },
        // { id: 'categories', name: 'Categories', to: 'categories', icon: faObjectGroup, children: false, hidden: !company.view_categories },
        // { id: 'courses', name: 'Courses', to: 'courses', icon: faPresentation, children: false },
        { id: 'courses', name: 'E-Learning Portal', icon: faPresentation, children: false, href: 'https://videotilehost.com/rhinosafety/', target: '_BLANK' },
        { id: 'documents', name: 'Documents', to: 'documents', icon: faFiles, children: false, hidden: !company.view_documents },
        { id: 'faq', name: 'Frequently Asked Questions', to: 'support/faq', icon: faQuestionCircle, children: false },
    ]

    const hideMenuDetail = () => {
        setIsMenuOpen(false);
        // setGlobal(prev => ({ ...prev, menuOpen: false }));
        setIsBackdropActive(false);
    };

    useOnClickOutside(menuBar, () => {
        hideMenuDetail();
    });

    const activateMenu = (menuName: string, withMenuDetail: boolean = true) => {
        if (activeMenus.includes(menuName) && isMenuOpen) {
            setIsMenuOpen(false);
            setIsBackdropActive(false);
        } else {
            setActiveMenus([menuName]);

            if (withMenuDetail) {
                setIsMenuOpen(true);
                setIsBackdropActive(true);
            } else {
                setIsBackdropActive(false);
            }
        }
    };

    const isOpen = (menuName: string) => {
        return activeMenus.includes(menuName) && isMenuOpen;
    };


    const isActive = (menuName: string) => {
        return activeMenus.includes(menuName);
    };

    return (
        <>
            <Backdrop active={isBackdropActive} workspaceOnly={true} />
            <Tippy
                singleton={source}
                touch={["hold", 500]}
                theme="light-border tooltip"
                offset={[0, 12]}
                animation="scale"
                placement="right"
                appendTo={document.body}
            // disabled={isTooltipDisabled}
            />
            <aside
                ref={menuBar}
                className={classNames(
                    "menu-bar",
                    "menu-sticky",
                    "menu-icon-only", // menu-default for names
                    { "menu-hidden": !global.menuOpen },
                    // { menu_branded: brandedMenu, },
                    // "menu-" + menuType
                )}
            >
                <div className="menu-items">
                    {menuItems.filter(x => !x.hidden).map(item => {
                        return (
                            <Tippy key={`main_menu${item.id}`} content={item.name} singleton={target}>
                                {
                                    item.children && !item.direct ?
                                        <button
                                            onClick={() => activateMenu(item.id, !!item.children)}
                                            className={classNames("link", { active: isActive(item.id) })}
                                        >
                                            {item.icon && <FontAwesomeIcon icon={item.icon} fixedWidth size="2x" />}
                                            <span className="title">{item.name}</span>
                                        </button>
                                        :
                                        item.to ?
                                            <Link
                                                to={item.to}
                                                onClick={() => activateMenu(item.id, !!item.children && !item.direct)}
                                                className={classNames("link", { active: isActive(item.id) })}
                                            >
                                                {item.icon && <FontAwesomeIcon icon={item.icon} fixedWidth size="2x" />}
                                                <span className="title">{item.name}</span>
                                            </Link>
                                            :
                                            item.href ?
                                                <a
                                                    href={item.href} target={item.target}
                                                    className={classNames("link", { active: isActive(item.id) })}
                                                >
                                                    {item.icon && <FontAwesomeIcon icon={item.icon} fixedWidth size="2x" />}
                                                    <span className="title">{item.name}</span>
                                                </a>
                                                : <h3>
                                                    <span className="title">{item.name}</span>
                                                </h3>
                                }
                            </Tippy>
                        )
                    })}
                </div>

                {menuItems.filter(x => !!x.children).map(item => {

                    return (
                        <div
                            key={item.id}
                            className={classNames("menu-detail", {
                                open: isOpen(item.id),
                            })}
                        >
                            {(item.children as PropsMenu[]).filter(x => !x.hidden).map(child => {
                                return (
                                    <div key={child.id} >
                                        <div className="menu-detail-wrapper">
                                            {child.to ?
                                                <NavLink to={child.to as string} end={!child.children} onClick={() => { setActiveSubMenu(child.to as string); if (child.closeMenu) { hideMenuDetail() } }}>
                                                    {child.icon && <FontAwesomeIcon icon={child.icon} fixedWidth />}
                                                    {child.name}
                                                </NavLink>
                                                :
                                                <>
                                                    <h6 className="flex gap-2 mt-8">
                                                        {child.icon && <FontAwesomeIcon icon={child.icon} fixedWidth />}
                                                        {child.name}
                                                    </h6>
                                                    <hr className="mb-4" />
                                                </>
                                            }
                                            {!!child.children && (activeSubMenu === child.to || child.childrenOpen) &&
                                                <>
                                                    {(child.children as PropsMenu[]).filter(x => !x.hidden).map(subChild => {
                                                        return (
                                                            <NavLink key={subChild.id} to={subChild.to as string} onClick={() => { if (subChild.closeMenu) { hideMenuDetail() } }}
                                                                className={"ml-4"}
                                                                end>
                                                                {subChild.icon && <FontAwesomeIcon icon={subChild.icon} fixedWidth />}
                                                                {subChild.name}
                                                            </NavLink>
                                                        )
                                                    })}
                                                </>}
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                            }
                        </div>
                    )
                })
                }

            </aside >
        </>

    )
}


export default SideBar;